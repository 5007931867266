import React, { Component } from "react"
import Layout from "../../components/layout"
import GatedContent from "../../components/gatedcontent"


import {CopyTextbox} from '../../components/copybox/'
import "../../styles/copybox.css"

export default class GitMaintenanceIndex extends Component {
	render() {
		return (
			<Layout showtitle={true} showtitleback={true} activePath='reference' fullPath='reference/git-maintenance' pageTitle="Git Basics" privatePage={true}>
				<GatedContent>
					<div className={"margin-content"}>
						Git is distributed source code respository, as opposed to centralized repositories like SVN and CVS. Listed here are common commands to maintain a git workspace via command line.  All commands are executed from the root folder of the workspace.
						<h2>Basic Commands</h2>
							<h3>Review changes</h3>
							<CopyTextbox copytext="git status" />
							<h3>Get difference</h3>
							<CopyTextbox copytext="git diff" />
							<h3>Add new files</h3>
							<div className={"copybox-textbox"}>
								git add &lt;path relative to workspace root; refer to git status&gt;
							</div>
							<h3>Commit changes</h3>
							<div className={"copybox-textbox"}>
								git commit -m "custom message"
							</div>
							<h3>Revert file/remove changes</h3>
							<div className={"copybox-textbox"}>
								git checkout &lt;path relative to workspace root; refer to git status&gt;
							</div>
							<h3>Retrieve latest version</h3>
							<CopyTextbox copytext="git pull" />
							<h3>Distribute/Push changes</h3>
							<CopyTextbox copytext="git push" />
						<h2>Tagging</h2>
						Useful when you want to assign names to versions
							<h3>Show Tags</h3>
							<CopyTextbox copytext="git tag" />
							<h3>Create Tag</h3>
							<div className={"copybox-textbox"}>
								git tag &lt;name&gt;
							</div>
							<h3>Share Tag</h3>
							<div className={"copybox-textbox"}>
								git push origin &lt;tag name&gt;
							</div>
							<h3>Loading a Tagged version</h3>
							<div className={"copybox-textbox"}>
								git checkout &lt;tag name&gt;
							</div>
							<h3>Load latest version</h3>
							<CopyTextbox copytext="git checkout master" />
						<h2>Advanced Commands</h2>
						Please be careful when using these commands
							<h3>Clone/Setup workspance</h3>
							<div className={"copybox-textbox"}>
								git clone &lt;git url&gt;
							</div>
							<h3>Add all files</h3>
							<div className={"copybox-textbox"}>
								git add --all
							</div>
							<h3>Commit all changes</h3>
							<div className={"copybox-textbox"}>
								git commit -a -m "custom message"
							</div>
							<h3>Retrieve branch</h3>
							<div className={"copybox-textbox"}>
								git pull origin &lt;branch name&gt;
							</div>
							<h3>Distribute/Push changes to a branch</h3>
							<div className={"copybox-textbox"}>
								git push origin &lt;branch name&gt;
							</div>
					</div>
				</GatedContent>
			</Layout>
		)
	}
}



